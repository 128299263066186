import React from 'react'
import PropTypes from 'prop-types'

import ContentfulText from '../../components/ContentfulText'
import './styles.scss'


const RestaurantPrivacyPage = (props) => {

  return (
    <div className='eg-restaurant-privacy'>
      <ContentfulText data={props.pageContext.content} />
    </div>
  )
}

RestaurantPrivacyPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default RestaurantPrivacyPage
